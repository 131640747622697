import React from "react";
import "../Password.less";
import { Button, Col, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";

const NewPassForm = () => {
  const [newPasswordForm] = Form.useForm();
  const navigate = useNavigate();

  const validateMessages = {
    required: "El campo es requerido.",
  };

  const onSave = () => {
    navigate("/");
  };

  return (
    <Form
      form={newPasswordForm}
      id="newPasswordForm"
      layout="vertical"
      onFinish={onSave}
      validateMessages={validateMessages}
    >
      <Row justify={"center"}>
        <Col xs={22} md={24}>
          <Form.Item
            label="Contraseña nueva"
            name="newPassword"
            rules={[
              { required: true },
              {
                pattern: /^[0-9]{4,4}$/,
                message: "La contraseña debe tener 4 carácteres numéricos.",
              },
            ]}
          >
            <Input.Password placeholder="Contraseña nueva" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={"center"}>
        <Col xs={22} md={24}>
          <Form.Item
            label="Repetir contraseña nueva"
            name="reenteredPassword"
            rules={[
              { required: true },
              {
                pattern: /^[0-9]{4,4}$/,
                message: "La contraseña debe tener 4 carácteres numéricos.",
              },
              {
                validator: async (_, reenteredPassword) => {
                  const newPassword =
                    newPasswordForm.getFieldValue("newPassword");
                  if (newPassword != null)
                    if (
                      reenteredPassword !== "" &&
                      reenteredPassword !== newPassword
                    ) {
                      return Promise.reject(
                        "Debes ingresar la misma contraseña que en el campo anterior."
                      );
                    }
                },
              },
            ]}
          >
            <Input.Password placeholder="Repetir contraseña nueva" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={"center"}>
        <Col xs={22} md={24}>
          <Form.Item>
            <div id="password-content__button">
              <Button id="passwordButton" type="primary" htmlType="submit">
                ENVIAR
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { NewPassForm };
