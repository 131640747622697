import axios from "axios";
let typeOfMethod = "private";
const BASE_URL = process.env.REACT_APP_URL_DEV;

const client = axios.create();

client.interceptors.request.use(
  (config) => {
    config.baseURL = BASE_URL;
    return config;
  },
  (e) => Promise.reject(e.response || e.message)
);

/**
 * Request Wrapper with default success/error actions
 */

const request = async (options) => {
  if (options.public) {
    typeOfMethod = "public";
  }

  let token = "";
  if (typeOfMethod === "private") {
    if (options.token) {
      token = options.token;
      console.log(options.token);
    } else {
      const storageToke = await localStorage.getItem("authToken");
      token = storageToke;
    }
    client.defaults.headers.common.Authorization = `Bearer ${
      token.token ? token.token : token
    }`;
  }

  const onSuccess = (response) => {
    return response;
  };

  const onError = (error) => {
    return Promise.reject(error.response || error.message || error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
