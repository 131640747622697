import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  generalNotifications: [],
  totalOfRegisterGeneralNot: 0,
  standarizedNotifications: [],
  totalOfRegisterStandarizedNot: 0,
  sentNotifications: [],
  totalOfRegisterSentNot: 0,
  sendingNotificationSuccess: false,
  lastNotificationReceivers: "",
  tableToShow: 1,
};

const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState: initialState,
  reducers: {
    setGeneralNotifications(state, { payload }) {
      state.generalNotifications = payload;
    },
    setTotalOfRegisterGeneralNot(state, { payload }) {
      state.totalOfRegisterGeneralNot = payload;
    },
    setStandarizedNotifications(state, { payload }) {
      state.standarizedNotifications = payload;
    },
    setTotalOfRegisterStandarizedNot(state, { payload }) {
      state.totalOfRegisterStandarizedNot = payload;
    },
    setSentNotifications(state, { payload }) {
      state.sentNotifications = payload;
    },
    setTotalOfRegisterSentNot(state, { payload }) {
      state.totalOfRegisterSentNot = payload;
    },
    setSendingNotificationSuccess(state, { payload }) {
      state.sendingNotificationSuccess = payload;
    },
    setLastNotificationReceivers(state, { payload }) {
      state.lastNotificationReceivers = payload;
    },
    setTableToShow(state, { payload }) {
      state.tableToShow = payload;
    },
  },
});

export const notificationsActions = notificationsSlice.actions;
export { notificationsSlice };
