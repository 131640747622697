import React from "react";
import "./CustomModal.less";
import { Modal } from "antd";

const CustomModal = ({
  visible,
  setVisible,
  onOk,
  cancelText,
  okText,
  children,
}) => {
  return (
    <Modal
      maskAnimation={false}
      transitionName={false}
      closable
      centered
      cancelText={cancelText}
      okText={okText}
      style={{ position: "sticky", bottom: "0 !important" }}
      open={visible}
      onOk={onOk}
      onCancel={() => setVisible(false)}
      width={"100%"}
    >
      {children}
    </Modal>
  );
};

export { CustomModal };
