import React from "react";
import { Link } from "react-router-dom";
import "./HomeCard.less";
import { CustomText } from "../../../../utils/CustomText";
import goAheadArrow from "../../../../assets/img/icons/arrow-blue-rigth.svg";

const HomeCard = ({ icon, title, listItems, linkName, linkRoute }) => {
  return (
    <div id="homeCard">
      <div id="cardTopContent">
        <div id="iconAndTitleContainer">
          <div id="iconContainer">
            {icon}
            <div id="iconCircle"></div>
          </div>
          <CustomText
            boldType={600}
            style={{ color: "#E3007D", fontSize: "20px" }}
          >
            {title}
          </CustomText>
        </div>
        <div id="listContainer">
          {listItems?.length > 0 &&
            listItems.map((item, i) => (
              <div key={i} className="listItem">
                <div className="listDot"></div>
                <CustomText style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  {item}
                </CustomText>
              </div>
            ))}
        </div>
      </div>
      <div id="cardBottomContent">
        <Link to={linkRoute} id="link">
          <CustomText style={{ color: "#00A9DF" }}>{linkName}</CustomText>
          <img src={goAheadArrow} alt="Go ahead arrow" />
        </Link>
      </div>
    </div>
  );
};

export { HomeCard };
