import React from "react";
import "./RoundButtonWithDropdown.less";
import { Button, Dropdown } from "antd";

const RoundButtonWithDropdown = ({
  buttonTitle,
  dropdownOverlay,
  style,
  ...restOfProps
}) => {
  const innerStyle = {
    borderRadius: "50px",
    width: "30px",
    height: "30px",
    padding: 0,
    paddingTop: "3px",
  };

  return (
    <div style={style}>
      {dropdownOverlay && (
        <Dropdown
          overlay={dropdownOverlay}
          placement="bottom"
          arrow
          trigger={["click"]}
        >
          <Button
            type="default"
            className="roundButton"
            style={innerStyle}
            {...restOfProps}
          >
            {buttonTitle}
          </Button>
        </Dropdown>
      )}
      {!dropdownOverlay && (
        <Button
          type="default"
          className="button-black-round"
          style={innerStyle}
          {...restOfProps}
        >
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};

export { RoundButtonWithDropdown };
