import request from "../../index";

function getWorkers(token, skip, searchingWord) {
  const thisSearchingWord = searchingWord ? searchingWord : "";
  const thisSkip = skip ? skip : 0;
  return request({
    url: `/colaborador/listar?skip=${thisSkip}&searchText=${thisSearchingWord}`,
    method: "GET",
    token,
  });
}

function getWorkerById(token, id) {
  return request({
    url: `/colaborador/get/${id}`,
    method: "GET",
    token,
  });
}

function createWorker(token, data) {
  return request({
    url: "/colaborador/create",
    method: "POST",
    data,
    token,
  });
}

function editWorker(token, id, data) {
  return request({
    url: `/colaborador/editar/${id}`,
    method: "PATCH",
    data,
    token,
  });
}

function changeWorkerState(token, id, data) {
  return request({
    url: `/colaborador/activate/${id}`,
    method: "PATCH",
    data,
    token,
  });
}

const WorkersServices = {
  getWorkers,
  getWorkerById,
  createWorker,
  editWorker,
  changeWorkerState,
};

export default WorkersServices;
