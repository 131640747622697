import React from "react";
import { Avatar, Menu } from "antd";
import "../InternalScreensLayout.less";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../auth";

const CustomHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.login);
  const { SubMenu } = Menu;

  const closeSession = () => {
    logOut(navigate, dispatch);
  };

  return (
    <div id="header">
      <div id="headerRightSide">
        <Menu
          mode="inline"
          id="menu-header"
          style={{ height: "100%", borderRight: 0 }}
        >
          <SubMenu
            key="sidebar-menu__usuarios"
            id="headerMenu"
            icon={
              <Avatar
                size="medium"
                id="headerMenuAvatar"
                style={{
                  backgroundColor: "#00A9DF",
                  verticalAlign: "middle",
                }}
              >
                {userData.nombre.split("")[0]}
              </Avatar>
            }
            title={`${userData.nombre} ${userData.apellidos}`}
          >
            <Menu.Item key="cerrarSesion" onClick={closeSession}>
              Cerrar sesión
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </div>
  );
};

export { CustomHeader };
