import React from "react";
import "./InternalScreensLayout.less";
import { MenuSider } from "./MenuSider";
import { CustomHeader } from "./CustomHeader";
import { Layout } from "antd";

const InternalScreensLayout = ({ children }) => {
  const { Header, Sider, Content } = Layout;

  return (
    <Layout id="internalScreensLayout">
      <Sider width="248">
        <MenuSider />
      </Sider>
      <Layout>
        <Header>
          <div id="header">
            <div id="header_logo"></div>
            <CustomHeader />
          </div>
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export { InternalScreensLayout };
