import React, { useEffect } from "react";
import "../App.less";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginActions } from "../redux/slices/login";
import { LoginScreen } from "../components/routes/LoginScreen";
import { PasswordScreen } from "../components/routes/PasswordScreen";
import { HomeScreen } from "../components/routes/HomeScreen";
import { UsersScreen } from "../components/routes/UsersScreen";
import { NotificationsScreen } from "../components/routes/Notifications/NotificationsScreen";
import { CreateOrCheckNotificationsScreen } from "../components/routes/Notifications/CreateOrCheckNotificationsScreen";
import { WorkersScreen } from "../components/routes/Workers/WorkersScreen";
import { CreateOrEditWorkerScreen } from "../components/routes/Workers/CreateOrEditWorkerScreen";
import { isLoginTokenValid } from "../utils/token";
import { useNavigate } from "react-router-dom";

const App = () => {
  const { loginState } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loginState && !isLoginTokenValid()) {
      dispatch(loginActions.logout());
      if (localStorage.getItem("authToken")) {
        localStorage.removeItem("authToken");
      }
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <div className="App">
      {!loginState || !isLoginTokenValid() ? (
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/password" element={<PasswordScreen />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/users" element={<UsersScreen />} />
          <Route path="/notifications" element={<NotificationsScreen />} />
          <Route
            path="/notifications/create/:notificationType/"
            element={<CreateOrCheckNotificationsScreen />}
          />
          <Route
            path="/notifications/create/:notificationType/:notificationId"
            element={<CreateOrCheckNotificationsScreen />}
          />
          <Route
            path="/notifications/check/:notificationType/:notificationId"
            element={<CreateOrCheckNotificationsScreen />}
          />
          <Route path="/workers" element={<WorkersScreen />} />
          <Route
            path="/workers/create"
            element={<CreateOrEditWorkerScreen />}
          />
          <Route
            path="/workers/edit/:workerId"
            element={<CreateOrEditWorkerScreen />}
          />
          {/* <Route path="*" component={NotFound} /> */}
        </Routes>
      )}
    </div>
  );
};

export default App;
