import React from "react";
import "../CreateOrCheckNotificationsScreen.less";
import { CustomText } from "../../../../../utils/CustomText";
import imagePreview from "../../../../../assets/img/imagePreview.jpg";
import arrowLeft from "../../../../../assets/img/icons/arrow-white-left.svg";
import logo from "../../../../../assets/img/logo_uno_white.svg";
import Parser from "html-react-parser";

const NotificationsPreview = ({
  notificationType,
  subject,
  shortMessage,
  largeMessage,
  messageImg,
  standarizedNotInfo,
}) => {
  const noPreviewRender = (
    <React.Fragment>
      <div id="imagePreviewContainer">
        <img src={imagePreview} alt="Preview" id="imagePreview" />
      </div>
      <div id="smallTextSimulator"></div>
      <div id="largeTextSimulator"></div>
    </React.Fragment>
  );

  const estandarNotPreview = (
    <React.Fragment>
      <CustomText
        boldType={600}
        style={{ fontSize: "18px", marginTop: "20px" }}
      >
        {subject}
      </CustomText>
      {Parser(largeMessage)}
    </React.Fragment>
  );

  const largeViewerRender = () => {
    if (notificationType === "general") {
      if (largeMessage !== "") {
        return Parser(largeMessage);
      } else {
        if (!messageImg) {
          return noPreviewRender;
        } else {
          return null;
        }
      }
    } else {
      if (largeMessage === "" && subject === "") {
        return noPreviewRender;
      } else {
        return estandarNotPreview;
      }
    }
  };

  return (
    <div id="NotificationsPreview">
      {notificationType === "general" ? (
        <React.Fragment>
          <CustomText boldType={600}>Notificación PUSH</CustomText>
          <div id="smallViewer">
            <div id="logoContainer">
              <img src={logo} alt="logo" id="logoUno" />
            </div>
            <div id="textContainer">
              <CustomText boldType={700}>
                {subject !== "" ? subject : "Asunto"}
              </CustomText>
              <CustomText>
                {shortMessage !== "" ? shortMessage : "Mensaje"}
              </CustomText>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <did id="clientDataContainer">
          <CustomText style={{ display: "flex" }}>
            <CustomText boldType={600}>Rut afiliado:&nbsp;</CustomText>{" "}
            {standarizedNotInfo.rut && standarizedNotInfo.rut}
          </CustomText>
          <CustomText style={{ display: "flex" }}>
            <CustomText boldType={600}>Fecha de envío:&nbsp;</CustomText>{" "}
            {standarizedNotInfo.date && standarizedNotInfo.date}
          </CustomText>
        </did>
      )}

      {notificationType === "general" && (
        <CustomText boldType={600}>Mensaje en la APP</CustomText>
      )}
      <div id="largeViewer">
        <div id="appHeaderSimulator">
          <img id="arrowIcon" src={arrowLeft} alt="Arrow" />
          <CustomText
            boldType={600}
            style={{ color: "#FFFFFF", fontSize: "22px" }}
          >
            Notificaciones
          </CustomText>
          <img id="hidenIcon" src={arrowLeft} alt="Arrow" />
        </div>

        {messageImg && (
          <img src={messageImg} alt="img" id="messageImagePreview" />
        )}
        <div id="viewerContentContainer">{largeViewerRender()}</div>
      </div>
    </div>
  );
};

export { NotificationsPreview };
