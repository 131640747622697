import React, { useState, useEffect } from "react";
import "./Password.less";
import { Button, Col, Form, Input, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { InitialScreensLayout } from "../../../utils/InitialScreensLayout";
import { CustomText } from "../../../utils/CustomText";
import { CustomAlert } from "../../../utils/CustomAlert";
import goBackArrow from "../../../assets/img/icons/arrow-blue-left.svg";
import { RecoveryPassForm } from "./RecoveryPassForm";
import { NewPassForm } from "./NewPassForm";
import { CustomLoader } from "../../../utils/CustomLoader";

const PasswordScreen = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [enterPassState, setEnterPassState] = useState(false);
  const [showTransparentLoader, setShowTransparentLoader] = useState(false);

  useEffect(() => {
    setEnterPassState(false);
  }, []);

  return (
    <React.Fragment>
      <CustomLoader visible={showTransparentLoader} semitransparent={true} />

      <InitialScreensLayout
        title={
          enterPassState ? "Nueva contraseña" : "Recuperación de contraseña"
        }
      >
        {error && <CustomAlert message={errorMessage} type={"error"} />}
        {!enterPassState && (
          <Link to={"/"} id="goBackLinkContainer">
            <img src={goBackArrow} alt="Go back arrow" />
            <p className="link goBlackLink">Volver</p>
          </Link>
        )}

        {enterPassState ? (
          <Row justify={"center"}>
            <Col xs={{ span: 24, offset: 6 }} md={{ span: 24, offset: 0 }}>
              <CustomText
                boldType={600}
                style={{ fontSize: "16px", marginBottom: "15px" }}
              >
                Ingresa tu nueva contraseña
              </CustomText>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <CustomText
                boldType={300}
                style={{
                  fontSize: "16px",
                  marginBottom: "15px",
                  textAlign: "left",
                }}
              >
                Ingresa tu correo electrónico y te enviaremos un mail para
                establecer una nueva contraseña.
              </CustomText>
            </Col>
          </Row>
        )}
        {enterPassState ? (
          <NewPassForm />
        ) : (
          <RecoveryPassForm
            setEnterPassState={setEnterPassState}
            setError={setError}
            setErrorMessage={setErrorMessage}
            setShowTransparentLoader={setShowTransparentLoader}
            navigate={navigate}
          />
        )}
      </InitialScreensLayout>
    </React.Fragment>
  );
};

export { PasswordScreen };
