import React from "react";
import "./CustomLoader.less";
import Lottie from "react-lottie";
import * as animationData from "../../assets/img/animated-logo.json";

const CustomLoader = ({ visible, solid, semitransparent, ...restOfProps }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      {visible && (
        <React.Fragment>
          <div
            className={`custom-loader-background ${
              solid
                ? "custom-solid-loader"
                : semitransparent
                ? "custom-semitransparent-loader"
                : "custom-transparent-loader"
            }`}
            {...restOfProps}
          ></div>
          <div className="custom-loader">
            <Lottie
              options={defaultOptions}
              height={200}
              width={200}
              style={{ opacity: 1 }}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export { CustomLoader };
