import React, { useState, useEffect } from "react";
import "./Users.less";
import { Input } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InternalScreensLayout } from "../../../utils/InternalScreensLayout";
import { CustomText } from "../../../utils/CustomText";
import { CustomButton } from "../../../utils/CustomButton";
import { CustomLoader } from "../../../utils/CustomLoader";
import { CustomAlert } from "../../../utils/CustomAlert";
import { applyAlert } from "../../../utils/utils";
import { UsersTable } from "./UsersTable";
import UsersServices from "../../../services/endpoints/UsersServices";
import { usersActions } from "../../../redux/slices/users";
import { isTokenValid } from "../../../utils/token";
import { logOut } from "../../../utils/auth";

const UsersScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const { Search } = Input;
  const [loadingData, setLoadingData] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchingWord, setSearchingWord] = useState("");

  const getUsers = async () => {
    setLoadingData(true);
    if (!isTokenValid(token)) {
      logOut(navigate, dispatch);
    } else {
      try {
        const response = await UsersServices.getUsers(token);
        dispatch(usersActions.setUsers(response.data.rows));
        dispatch(usersActions.setTotalOfRegisters(response.data.total));
        setLoadingData(false);
      } catch (e) {
        let errorMessage =
          "Ocurrió un error en la comunicación con el servidor.";
        if (e.data) {
          if (e.data.message) {
            errorMessage = e.data.message;
          } else {
            errorMessage = "Ocurrió un error al consultar datos de usuarios.";
          }
        }
        applyAlert(setError, setErrorMessage, errorMessage);
        setLoadingData(false);
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleApplySearch = (value) => {
    setSearchingWord(value);
  };

  const handleDownloadReport = async () => {
    setLoadingScreen(true);
    if (!isTokenValid(token)) {
      logOut(navigate, dispatch);
    } else {
      try {
        const response = await UsersServices.getUsersReport(token);
        const data = response.data;

        const file = new Blob([data], { type: "text/csv;charset=utf-8;" });
        const fileUrl = URL.createObjectURL(file);
        let link = document.createElement("a");
        link.setAttribute("href", fileUrl);
        link.setAttribute("download", "reporteusuarios.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoadingScreen(false);
      } catch (e) {
        let errorMessage =
          "Ocurrió un error en la comunicación con el servidor.";
        if (e.data) {
          if (e.data.message) {
            errorMessage = e.data.message;
          } else {
            errorMessage = "Ocurrió un error al solicitar el reporte.";
          }
        }
        applyAlert(setError, setErrorMessage, errorMessage);
        setLoadingScreen(false);
      }
    }
  };

  return (
    <React.Fragment>
      <CustomLoader visible={loadingScreen} />
      <InternalScreensLayout>
        {error && <CustomAlert message={errorMessage} type={"error"} />}
        <div id="users">
          <div id="usersTitle">
            <CustomText
              boldType={600}
              style={{ fontSize: "24px", color: "#E3007D" }}
            >
              Usuarios registrados APP
            </CustomText>
          </div>
          <div id="inputAndButtonContainer">
            <Search
              enterButton
              allowClear
              placeholder="Buscar"
              onSearch={handleApplySearch}
              style={{ width: "360px" }}
            />
            <CustomButton
              onClick={handleDownloadReport}
              title={"DESCARGAR REPORTE"}
              icon={<DownloadOutlined style={{ fontSize: "18px" }} />}
            />
          </div>
          <UsersTable
            loading={loadingData}
            setLoading={setLoadingData}
            searchingWord={searchingWord}
          />
        </div>
      </InternalScreensLayout>
    </React.Fragment>
  );
};

export { UsersScreen };
