import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import "../Notifications.less";
import NotificationsServices from "../../../../../services/endpoints/NotificationsServices";

const SentNotificationsTable = ({
  setLoading,
  loading,
  searchingWord,
  setSearchingError,
}) => {
  const token = localStorage.getItem("authToken");
  const { sentNotifications, totalOfRegisterSentNot } = useSelector(
    (state) => state.notifications
  );
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: totalOfRegisterSentNot,
      pageSizeOptions: [],
    },
  });
  const [notificationsToShow, setNotidicationsToShow] = useState([]);

  const columns = [
    {
      title: "Notificación",
      key: 1,
      sorter: (a, b) => a.titulo.toString().localeCompare(b.titulo),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p>{record.tipo === "general" ? record.titulo : record.campana}</p>
      ),
    },
    {
      title: "Tipo",
      key: 2,
      sorter: (a, b) => a.tipo.toString().localeCompare(b.tipo),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <div
          id={
            record.tipo === "general" ? "generalesType" : "estandarizadasType"
          }
        >
          <p>{record.tipo === "general" ? "General" : "Estandarizada"}</p>
        </div>
      ),
    },
    {
      title: "Receptor",
      key: 3,
      sorter: (a, b) =>
        a.personasEnvio.toString().localeCompare(b.personasEnvio),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p>{record.toAll ? "Todos" : record.personasEnvio}</p>
      ),
    },
    {
      title: "Leído por",
      key: 4,
      sorter: (a, b) => a.leidas.toString().localeCompare(b.leidas),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.leidas} usuarios</p>,
    },
    {
      title: "Fecha de envío",
      key: 5,
      sorter: (a, b) => a.fechaEnvio.toString().localeCompare(b.fechaEnvio),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.fechaEnvio}</p>,
    },
  ];

  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    try {
      const skip = 20 * pagination.current - 20;
      const response = await NotificationsServices.getNotifications(
        token,
        null,
        skip
      );
      console.log("aqui", response);
      setNotidicationsToShow(response.data.notificaciones);
      setTableParams({
        pagination,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleApplySearchingWord = async () => {
    setLoading(true);
    try {
      const response = await NotificationsServices.getNotifications(
        token,
        null,
        0,
        searchingWord
      );
      if (response.data.notificaciones.length === 0) {
        setSearchingError(true);
      }
      setNotidicationsToShow(response.data.notificaciones);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: response.data.count,
          pageSizeOptions: [],
        },
      });
      setLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    if (searchingWord !== "") {
      handleApplySearchingWord();
    } else {
      setNotidicationsToShow([]);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: totalOfRegisterSentNot,
          pageSizeOptions: [],
        },
      });
    }
  }, [searchingWord]);

  return (
    <div id="sentNotificationsTable">
      {(sentNotifications || notificationsToShow.length > 0) && (
        <Table
          loading={loading}
          columns={columns}
          pagination={tableParams.pagination}
          dataSource={
            notificationsToShow.length > 0
              ? notificationsToShow
              : sentNotifications
          }
          onChange={handleTableChange}
        />
      )}
    </div>
  );
};

export { SentNotificationsTable };
