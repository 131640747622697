import React, { useState, useEffect } from "react";
import { Switch, Upload, Form, Input, Button } from "antd";
import "../CreateOrCheckNotificationsScreen.less";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { CustomText } from "../../../../../utils/CustomText";
import { CustomButton } from "../../../../../utils/CustomButton";
import folderBlueIcon from "../../../../../assets/img/icons/folder_blue_icon.svg";
import { applyAlert } from "../../../../../utils/utils";
import { CustomAlert } from "../../../../../utils/CustomAlert";
import { UploadOutlined } from "@ant-design/icons";

const NotificationsForm = ({
  notificationType,
  handleOnChangeCampain,
  handleOnChangeSubject,
  handleOnChangeShortMessage,
  handleOnChangeLargeMessage,
  largeMessage,
  allUsersSelected,
  handleAllUsersSelected,
  usersSVG,
  setUsersSVG,
  params,
  subject,
  initialNotification,
  setShowConfirmationModal,
  messageImg,
  setMessageImg,
  onImagePreview,
  prevImgList,
  setPrevImgList,
}) => {
  const thereIsId = params.notificationId ? true : false;
  const [notificationsForm] = Form.useForm();
  const { TextArea } = Input;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (thereIsId) {
      notificationsForm.setFieldsValue({
        name: initialNotification.campain,
        subject: initialNotification.subject,
        largeMessage: BraftEditor.createEditorState(
          initialNotification.largeMessage
        ),
      });
      if (notificationType === "general") {
        notificationsForm.setFieldsValue({
          shortMessage: initialNotification.shortMessage,
        });
      }
    }
  }, [thereIsId, params, initialNotification, notificationType]);

  const validateMessages = {
    required: "El campo es requerido.",
    whitespace: "El campo es requerido.",
  };

  const csvUploadProps = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    accept: ".csv",
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        setUsersSVG(info.file.originFileObj);
      } else if (info.file.status === "error") {
        applyAlert(
          setError,
          setErrorMessage,
          "Hubo un error al cargar el archivo."
        );
      }
    },
    onRemove() {
      setUsersSVG(null);
    },
  };

  const imageUploadProps = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    accept: ".jpg, .jpeg, .png",
    title: "Sube una imagen",
    name: "file",
    fileList: prevImgList,
    onChange(thisFile) {
      if (thisFile.file.status !== "removed") {
        onImagePreview(thisFile.file);
        setPrevImgList([thisFile.file]);
      }
    },
    onRemove() {
      if (prevImgList) {
        setPrevImgList(null);
      }
      setMessageImg(null);
    },
  };

  return (
    <React.Fragment>
      {error && <CustomAlert message={errorMessage} type={"error"} />}

      <div id="NotificationsForm">
        <Form
          layout="vertical"
          form={notificationsForm}
          validateMessages={validateMessages}
          onFinish={() => setShowConfirmationModal(true)}
        >
          <div id="switchContainer">
            <Switch
              checked={allUsersSelected}
              onChange={handleAllUsersSelected}
            />
            <CustomText>Enviar notificación a todos los usuarios</CustomText>
          </div>
          {!allUsersSelected && (
            <React.Fragment>
              <Form.Item
                name={["destination"]}
                rules={[
                  {
                    validator: async () => {
                      if (usersSVG === null && allUsersSelected === false) {
                        return Promise.reject("Debes definir destinatarios.");
                      }
                    },
                  },
                ]}
              >
                <Upload {...csvUploadProps}>
                  <CustomButton
                    style={{
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                    icon={<img src={folderBlueIcon} alt="Folder icon" />}
                    title="Cargar destinatarios"
                    type={"default"}
                    disabled={usersSVG}
                  />
                </Upload>
                {!usersSVG && (
                  <CustomText style={{ color: "#8C8C8C", marginTop: "5px" }}>
                    Selecciona un archivo CSV para importar.
                  </CustomText>
                )}
              </Form.Item>
            </React.Fragment>
          )}
          <Form.Item
            style={{ marginTop: "15px" }}
            label="Nombre de la campaña"
            name={["name"]}
            rules={[
              {
                required: true,
              },
              {
                whitespace: true,
              },
              {
                pattern: /^[\s\S]{10,25}$/,
                message: "Ocupa un mínimo de 10 y máximo de 25 caracteres.",
              },
            ]}
          >
            <Input
              placeholder="Nombre de la campaña"
              onChange={(e) => handleOnChangeCampain(e)}
            />
          </Form.Item>
          <Form.Item
            label="Asunto"
            name={["subject"]}
            rules={[
              {
                required: true,
              },
              {
                whitespace: true,
              },
              {
                pattern: /^[\s\S]{10,25}$/,
                message: "Ocupa un mínimo de 10 y máximo de 25 caracteres.",
              },
            ]}
          >
            <Input
              placeholder="Asunto"
              onChange={(e) => handleOnChangeSubject(e)}
              value={subject}
            />
          </Form.Item>
          {notificationType === "general" && (
            <Form.Item
              label="Mensaje corto"
              name={["shortMessage"]}
              rules={[
                {
                  required: true,
                },
                {
                  whitespace: true,
                },
                {
                  pattern: /^[\s\S]{10,25}$/,
                  message: "Ocupa un mínimo de 10 y máximo de 25 caracteres.",
                },
              ]}
            >
              <TextArea
                rows={3}
                placeholder="Mensaje corto"
                onChange={(e) => handleOnChangeShortMessage(e)}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Imagen"
            name={["image"]}
            // rules={[
            //   {
            //     validator: async () => {
            //       if (!messageImg) {
            //         return Promise.reject("La imagen es requerida.");
            //       }
            //     },
            //   },
            // ]}
          >
            <Upload {...imageUploadProps}>
              <Button disabled={messageImg} icon={<UploadOutlined />}>
                Sube una imagen
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Mensaje largo"
            name={["largeMessage"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <BraftEditor
              controls={[
                "bold",
                "italic",
                "underline",
                "remove-styles",
                "emoji",
                "change-size",
                "text-align",
                "link",
              ]}
              style={{
                border: "1px solid #bfbfbf",
                borderRadius: "10px",
                overflow: "hidden",
              }}
              value={largeMessage}
              onChange={handleOnChangeLargeMessage}
              placeholder="Mensaje largo"
              language={"en"}
              fontSizes={"14px"}
              contentStyle={{
                fontSize: "14px",
                height: 210,
                boxShadow: "inset 0 1px 3px rgba(0,0,0,.1)",
              }}
            />
          </Form.Item>
          <Form.Item>
            <CustomButton
              title={"Enviar"}
              type="primary"
              htmlType="submit"
              id="formButton"
            />
          </Form.Item>
        </Form>
      </div>
    </React.Fragment>
  );
};

export { NotificationsForm };
