import React, { useState, useEffect } from "react";
import "./Workers.less";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Input, notification } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { InternalScreensLayout } from "../../../../utils/InternalScreensLayout";
import { CustomText } from "../../../../utils/CustomText";
import { CustomButton } from "../../../../utils/CustomButton";
import { CustomAlert } from "../../../../utils/CustomAlert";
import { WorkersTable } from "./WorkersTable";
import { CustomModal } from "../../../../utils/CustomModal";
import { openSuccessNotification } from "../../../../utils/notifications";
import { DeactivateConfirmationModalTemplate } from "./DeactivateConfirmationModalTemplate";
import WorkersServices from "../../../../services/endpoints/WorkersServices";
import { workersActions } from "../../../../redux/slices/workers";
import { isTokenValid } from "../../../../utils/token";
import { logOut } from "../../../../utils/auth";
import { applyAlert } from "../../../../utils/utils";
import { CustomLoader } from "../../../../utils/CustomLoader";

const WorkersScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const { Search } = Input;
  const { workerCreated, workerEdited, workerStateChanged } = useSelector(
    (state) => state.workers
  );
  const [loadingData, setLoadingData] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [targetWorker, setTargetWorker] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDeactivateConfirmationModal, setShowDeactivateConfirmationModal] =
    useState(false);
  const [searchingWord, setSearchingWord] = useState("");

  const getWorkers = async () => {
    setLoadingData(true);
    if (!isTokenValid(token)) {
      logOut(navigate, dispatch);
    } else {
      try {
        const response = await WorkersServices.getWorkers(token);

        dispatch(workersActions.setWorkers(response.data.rows));
        dispatch(workersActions.setTotalOfRegisters(response.data.total));
        setLoadingData(false);
      } catch (e) {
        let errorMessage =
          "Ocurrió un error en la comunicación con el servidor.";
        if (e.data) {
          if (e.data.message) {
            errorMessage = e.data.message;
          } else {
            errorMessage =
              "Ocurrió un error al consultar datos de colaboradores.";
          }
        }
        applyAlert(setError, setErrorMessage, errorMessage);
        setLoadingData(false);
      }
    }
  };

  useEffect(() => {
    getWorkers();
  }, []);

  useEffect(() => {
    if (workerEdited) {
      console.log("Aqui");
      openSuccessNotification({
        key: "workerEditedSuccess",
        message: "El/la Colaborador/a fue modificado/a exitosamente.",
        btn: (
          <CustomButton
            title="Aceptar"
            style={{ height: "24px", width: "86px", borderRadius: "4px" }}
            onClick={() => notification.close("workerEditedSuccess")}
          />
        ),
      });
      dispatch(workersActions.setWorkerEdited(false));
      getWorkers();
    }
  }, [workerEdited]);

  useEffect(() => {
    if (workerCreated) {
      openSuccessNotification({
        key: "workerCreationSuccess",
        message: "El/la Colaborador/a fue creado/a exitosamente.",
        btn: (
          <CustomButton
            title="Aceptar"
            style={{ height: "24px", width: "86px", borderRadius: "4px" }}
            onClick={() => notification.close("workerCreationSuccess")}
          />
        ),
      });
      dispatch(workersActions.setWorkerCreated(false));
      getWorkers();
    }
  }, [workerCreated]);

  useEffect(() => {
    if (workerStateChanged) {
      openSuccessNotification({
        key: "workerStateUpdate",
        message: "Estado actualizado exitosamente.",
        btn: (
          <CustomButton
            title="Aceptar"
            style={{ height: "24px", width: "86px", borderRadius: "4px" }}
            onClick={() => notification.close("workerStateUpdate")}
          />
        ),
      });
      dispatch(workersActions.setWorkerStateChanged(false));
      getWorkers();
    }
  }, [workerStateChanged]);

  const handleApplySearch = (value) => {
    setSearchingWord(value);
  };

  const handleCreateWorker = () => {
    navigate("/workers/create");
  };

  const handleShowDeactivationModal = (worker) => {
    setTargetWorker(worker);
    setShowDeactivateConfirmationModal(true);
  };

  const handleConfirmDeactivateWorker = async () => {
    setShowDeactivateConfirmationModal(false);
    setLoadingScreen(true);
    try {
      const data = {
        active: false,
      };
      await WorkersServices.changeWorkerState(token, targetWorker.id, data);
      dispatch(workersActions.setWorkerStateChanged(true));
      getWorkers();
      setLoadingScreen(false);
    } catch (e) {
      let errorMessage = "Ocurrió un error en la comunicación con el servidor.";
      if (e.data) {
        if (e.data.message) {
          errorMessage = e.data.message;
        } else {
          errorMessage =
            "Ocurrió un error al consultar datos de colaboradores.";
        }
      }
      applyAlert(setError, setErrorMessage, errorMessage);
      setLoadingScreen(false);
    }
  };

  return (
    <React.Fragment>
      <CustomLoader visible={loadingScreen} />
      <InternalScreensLayout>
        {error && <CustomAlert message={errorMessage} type={"error"} />}
        <CustomModal
          onOk={handleConfirmDeactivateWorker}
          visible={showDeactivateConfirmationModal}
          setVisible={setShowDeactivateConfirmationModal}
          cancelText="Cancelar"
          okText="Sí, desactivar"
        >
          <DeactivateConfirmationModalTemplate targetWorker={targetWorker} />
        </CustomModal>
        <div id="workers">
          <div id="workersTitle">
            <CustomText
              boldType={600}
              style={{ fontSize: "24px", color: "#E3007D" }}
            >
              Colaboradores
            </CustomText>
          </div>
          <div id="inputAndButtonContainer">
            <Search
              enterButton
              allowClear
              placeholder="Buscar"
              onSearch={handleApplySearch}
              style={{ width: "360px" }}
            />
            <CustomButton
              title={"CREAR COLABORADOR"}
              icon={<UserAddOutlined style={{ fontSize: "18px" }} />}
              onClick={handleCreateWorker}
            />
          </div>
          <WorkersTable
            handleShowDeactivationModal={handleShowDeactivationModal}
            loading={loadingData}
            setLoading={setLoadingData}
            searchingWord={searchingWord}
            getWorkers={getWorkers}
            setLoadingScreen={setLoadingScreen}
            applyAlert={applyAlert}
          />
        </div>
      </InternalScreensLayout>
    </React.Fragment>
  );
};

export { WorkersScreen };
