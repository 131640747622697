import moment from "moment";
import jwt_decode from "jwt-decode";

//Decodificar token
const parseJwt = (token) => {
  try {
    const jsonPayload = jwt_decode(token);
    return jsonPayload;
  } catch (error) {
    return undefined;
  }
};

//Corroborar que token continúa siendo válido
const isTokenValid = (token) => {
  if (!token) {
    return false;
  }
  const data = parseJwt(token);
  if (data !== undefined) {
    return moment.unix(data.exp).isAfter(new Date());
  }
  return false;
};

const isLoginTokenValid = () => {
  const token = localStorage.getItem("authToken");
  if (!token) {
    return false;
  }
  if (!isTokenValid(token)) {
    localStorage.removeItem("authToken");
    return false;
  }
  return isTokenValid(token);
};

export { isTokenValid, parseJwt, isLoginTokenValid };
