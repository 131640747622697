import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginState: false,
  userData: {},
  loginLoaderState: false,
  newPassSent: false,
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState: initialState,
  reducers: {
    login(state, { payload }) {
      state.loginState = true;
      state.userData = payload;
    },
    setLoginLoaderState(state, { payload }) {
      state.telon = payload;
    },
    logout(state) {
      state.loginState = false;
      state.userData = {};
    },
    setNewPassSent(state, { payload }) {
      state.newPassSent = payload;
    },
  },
});

export const loginActions = loginSlice.actions;
export { loginSlice };
