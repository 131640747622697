import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  totalOfRegisters: 0,
};

const usersSlice = createSlice({
  name: "usersSlice",
  initialState: initialState,
  reducers: {
    setUsers(state, { payload }) {
      state.users = payload;
    },
    setTotalOfRegisters(state, { payload }) {
      state.totalOfRegisters = payload;
    },
  },
});

export const usersActions = usersSlice.actions;
export { usersSlice };
