import React, { useState, useEffect } from "react";
import "./Login.less";
import { Col, Row, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { InitialScreensLayout } from "../../../utils/InitialScreensLayout";
import { CustomText } from "../../../utils/CustomText";
import { CustomAlert } from "../../../utils/CustomAlert";
import { CustomLoader } from "../../../utils/CustomLoader";
import { CustomButton } from "../../../utils/CustomButton";
import { LoginForm } from "./LoginForm";
import { applyAlert } from "../../../utils/utils";
import { parseJwt } from "../../../utils/token";
import { loginActions } from "../../../redux/slices/login";
import LoginServices from "../../../services/endpoints/LoginServices";
import { openSuccessNotification } from "../../../utils/notifications";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [showTransparentLoader, setShowTransparentLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { loadingLoaderState, newPassSent } = useSelector(
    (state) => state.login
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setShowTransparentLoader(false);
    setError(false);
    setErrorMessage(false);
  }, []);

  const validateMessages = {
    required: "El campo es requerido.",
    types: {
      email: "El correo debe tener un formato válido.",
    },
  };

  useEffect(() => {
    if (newPassSent) {
      openSuccessNotification({
        key: "passwordRecoverySuccess",
        message: "Se envió una nueva contraseña a tu correo electrónico.",
        btn: (
          <CustomButton
            title="Aceptar"
            style={{ height: "24px", width: "86px", borderRadius: "4px" }}
            onClick={() => notification.close("passwordRecoverySuccess")}
          />
        ),
      });
      dispatch(loginActions.setNewPassSent(false));
    }
  }, [newPassSent]);

  const onFinish = async (values) => {
    setShowTransparentLoader(true);
    try {
      const response = await LoginServices.authenticate(values);

      setShowTransparentLoader(false);
      console.log(response);
      dispatch(loginActions.setLoginLoaderState(true));
      localStorage.setItem("authToken", response.data.token);
      const tokenData = parseJwt(response.data.token);
      dispatch(loginActions.login(tokenData));
      dispatch(loginActions.setLoginLoaderState(false));
      navigate("/home");
    } catch (e) {
      console.log(e);
      let errorMessage =
        "Ocurrió un error en la comunicación con el servidor, vuelve a intentarlo más tarde.";
      if (e.data) {
        if (e.data.message) {
          errorMessage = "Rut y/o contraseña inválidos.";
        } else {
          errorMessage = "Ocurrió un error, intenta de nuevo más tarde.";
        }
      }
      applyAlert(setError, setErrorMessage, errorMessage);
      setShowTransparentLoader(false);
    }
  };

  return (
    <React.Fragment>
      <CustomLoader visible={loadingLoaderState} solid={true} />
      <React.Fragment>
        <CustomLoader visible={showTransparentLoader} semitransparent={true} />
        {error && <CustomAlert message={errorMessage} type={"error"} />}
        <InitialScreensLayout title={"Gestión de Notificaciones"}>
          <Row justify={"center"}>
            <Col xs={{ span: 24, offset: 6 }} md={{ span: 24, offset: 0 }}>
              <CustomText
                boldType={600}
                style={{ fontSize: "16px", marginBottom: "15px" }}
              >
                Ingresa tus datos para comenzar
              </CustomText>
            </Col>
          </Row>
          <LoginForm onFinish={onFinish} validateMessages={validateMessages} />
          <Row justify={"center"}>
            <Col xs={{ span: 24, offset: 16 }} md={{ span: 24, offset: 0 }}>
              <Link to={"/password"}>
                <p className="link" style={{ alignSelf: "center" }}>
                  ¿Olvidaste tu contraseña?
                </p>
              </Link>
            </Col>
          </Row>
        </InitialScreensLayout>
      </React.Fragment>
      )}
    </React.Fragment>
  );
};

export { LoginScreen };
