import React, { useEffect } from "react";
import { Form, Input, Radio } from "antd";
import "../CreateOrEditWorkerScreen.less";
import { CustomText } from "../../../../../utils/CustomText";
import { CustomButton } from "../../../../../utils/CustomButton";

const WorkerForm = ({
  handleCreateOrEdit,
  editMode,
  params,
  initialWorker,
}) => {
  const [workerForm] = Form.useForm();

  useEffect(() => {
    if (editMode) {
      workerForm.setFieldsValue({
        names: initialWorker.names,
        lastnames: initialWorker.lastnames,
        email: initialWorker.email,
        rol: initialWorker.rol,
      });
    } else {
      workerForm.setFieldsValue({
        rol: 1,
      });
    }
  }, [editMode, params, initialWorker]);

  const validateMessages = {
    required: "El campo es requerido.",
    whitespace: "El campo es requerido.",
    types: {
      email: "El correo debe tener un formato válido.",
    },
  };

  return (
    <div id="workerForm">
      <Form
        layout="vertical"
        form={workerForm}
        validateMessages={validateMessages}
        onFinish={handleCreateOrEdit}
      >
        <CustomText boldType={600}>Datos personales</CustomText>
        <Form.Item
          style={{ marginTop: "15px" }}
          label="Nombres"
          name={["names"]}
          rules={[
            {
              required: true,
            },
            {
              whitespace: true,
            },
            {
              pattern: /^[\s\S]{2,25}$/,
              message: "Ocupa un mínimo de 2 y máximo de 25 caracteres.",
            },
          ]}
        >
          <Input placeholder="Nombres" />
        </Form.Item>
        <Form.Item
          label="Apellidos"
          name={["lastnames"]}
          rules={[
            {
              required: true,
            },
            {
              whitespace: true,
            },
            {
              pattern: /^[\s\S]{2,25}$/,
              message: "Ocupa un mínimo de 2 y máximo de 25 caracteres.",
            },
          ]}
        >
          <Input placeholder="Apellidos" />
        </Form.Item>
        <Form.Item
          label="Correo electrónico"
          name={["email"]}
          rules={[
            {
              type: "email",
            },
            {
              required: true,
            },
            {
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Correo electrónico" disabled={editMode} />
        </Form.Item>
        <Form.Item label="Seleccionar rol" name={["rol"]}>
          <Radio.Group>
            <Radio value={"1"}>Administrador</Radio>
            <Radio value={"2"}>Ejecutivo</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <CustomButton
            title={editMode ? "Guardar cambios" : "Crear"}
            type="primary"
            htmlType="submit"
            id="formButton"
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export { WorkerForm };
