import request from "../../index";

function getUsers(token, skip, searchingWord) {
  const thisSearchingWord = searchingWord ? searchingWord : "";
  const thisSkip = skip ? skip : 0;
  return request({
    url: `/afiliado/listar?skip=${thisSkip}&searchText=${thisSearchingWord}`,
    method: "GET",
    token,
  });
}

function getUsersReport(token) {
  return request({
    url: "/afiliado/report",
    method: "GET",
    token,
  });
}

const UsersServices = {
  getUsers,
  getUsersReport,
};

export default UsersServices;
