import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { RoundButtonWithDropdown } from "../../../../../utils/RoundButtonWithDropDown";
import "../Notifications.less";
import NotificationsServices from "../../../../../services/endpoints/NotificationsServices";

const StandardizedNotificationsTable = ({
  loading,
  setLoading,
  searchingWord,
  setSearchingError,
}) => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const { standarizedNotifications, totalOfRegisterStandarizedNot } =
    useSelector((state) => state.notifications);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: totalOfRegisterStandarizedNot,
      pageSizeOptions: [],
    },
  });
  const [notificationsToShow, setNotidicationsToShow] = useState([]);

  const handleCheck = (id) => {
    navigate(`/notifications/check/estandarizada/${id}`);
  };

  console.log(standarizedNotifications);
  const columns = [
    {
      title: "Notificación",
      key: 1,
      sorter: (a, b) => a.campana.toString().localeCompare(b.campana),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.campana}</p>,
    },
    {
      title: "Rut Afiliado",
      key: 2,
      sorter: (a, b) =>
        a.afiliado?.rut.toString().localeCompare(b.afiliado?.rut),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.afiliado?.rut}</p>,
    },
    {
      title: "Fecha de envío",
      key: 3,
      sorter: (a, b) => a.fechaEnvio.toString().localeCompare(b.fechaEnvio),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.fechaEnvio}</p>,
    },
    {
      title: "Acción",
      key: 4,
      render: (record) => (
        <p className="detailsLink" onClick={() => handleCheck(record.id)}>
          Ver detalle
        </p>
      ),
    },
  ];

  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    try {
      const skip = 20 * pagination.current - 20;
      const response = await NotificationsServices.getNotifications(
        token,
        "estandar",
        skip
      );
      setNotidicationsToShow(response.data.notificaciones);
      setTableParams({
        pagination,
      });
      setLoading(false);
    } catch (e) {}
  };

  const handleApplySearchingWord = async () => {
    setLoading(true);
    try {
      const response = await NotificationsServices.getNotifications(
        token,
        "estandar",
        0,
        searchingWord
      );
      if (response.data.notificaciones.length === 0) {
        setSearchingError(true);
      }
      setNotidicationsToShow(response.data.notificaciones);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: response.data.count,
          pageSizeOptions: [],
        },
      });
      setLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    if (searchingWord !== "") {
      handleApplySearchingWord();
    } else {
      setNotidicationsToShow([]);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: totalOfRegisterStandarizedNot,
          pageSizeOptions: [],
        },
      });
    }
  }, [searchingWord]);

  return (
    <div id="standardizedNotificationsTable">
      {(standarizedNotifications || notificationsToShow.length > 0) && (
        <Table
          loading={loading}
          pagination={tableParams.pagination}
          columns={columns}
          dataSource={
            notificationsToShow.length > 0
              ? notificationsToShow
              : standarizedNotifications
          }
          onChange={handleTableChange}
        />
      )}
    </div>
  );
};

export { StandardizedNotificationsTable };
