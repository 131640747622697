import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import "./UsersTable.less";
import UsersServices from "../../../../services/endpoints/UsersServices";

const UsersTable = ({ setLoading, loading, searchingWord }) => {
  const token = localStorage.getItem("authToken");
  const { users, totalOfRegisters } = useSelector((state) => state.users);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: totalOfRegisters,
      pageSizeOptions: [],
    },
  });
  const [usersToShow, setUsersToShow] = useState([]);

  const columns = [
    {
      title: "ID",
      key: 1,
      sorter: (a, b) => a.id.toString().localeCompare(b.id),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.id}</p>,
    },
    {
      title: "RUT",
      key: 2,
      sorter: (a, b) => a.rut.toString().localeCompare(b.rut),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.rut}</p>,
    },
    {
      title: "Nombre",
      key: 3,
      sorter: (a, b) => a.nombre.toString().localeCompare(b.nombre),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.nombre}</p>,
    },
    {
      title: "Apellidos",
      key: 3,
      sorter: (a, b) => a.apellidos.toString().localeCompare(b.apellidos),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.apellidos}</p>,
    },
    {
      title: "Activo",
      key: 4,
      sorter: (a, b) => a.activo.toString().localeCompare(b.activo),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <div className="tableLoginData">
          <p>{record.activo ? "Sí" : "No"}</p>
          {record.activo && <CheckOutlined style={{ color: "#27AE60" }} />}
        </div>
      ),
    },
    {
      title: "Fecha de creación",
      key: 5,
      sorter: (a, b) => a.createdAt.toString().localeCompare(b.createdAt),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.createdAt}</p>,
    },
  ];

  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    try {
      const skip = 20 * pagination.current - 20;
      const response = await UsersServices.getUsers(token, skip);
      setUsersToShow(response.data.rows);
      setTableParams({
        pagination,
      });
      setLoading(false);
    } catch (e) {}
  };

  const handleApplySearchingWord = async () => {
    setLoading(true);
    try {
      const response = await UsersServices.getUsers(token, 0, searchingWord);
      setUsersToShow(response.data.rows);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: response.data.total,
          pageSizeOptions: [],
        },
      });
      setLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    if (searchingWord !== "") {
      handleApplySearchingWord();
    } else {
      setUsersToShow([]);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: totalOfRegisters,
          pageSizeOptions: [],
        },
      });
    }
  }, [searchingWord]);

  return (
    <div id="usersTable">
      {users && (
        <Table
          loading={loading}
          pagination={tableParams.pagination}
          columns={columns}
          dataSource={usersToShow.length > 0 ? usersToShow : users}
          onChange={handleTableChange}
        />
      )}
    </div>
  );
};

export { UsersTable };
