import React from "react";
import "../Login.less";
import { Button, Col, Form, Input, Row } from "antd";

const LoginForm = ({ onFinish, validateMessages }) => {
  return (
    <Form
      id="loginForm"
      layout="vertical"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Row justify={"center"}>
        <Col xs={22} md={24}>
          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
            <Input placeholder="Correo electrónico" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={"center"}>
        <Col xs={22} md={24}>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: "Ingresa contraseña" }]}
          >
            <Input.Password placeholder="Contraseña" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={"center"}>
        <Col xs={22} md={24}>
          <Form.Item>
            <div id="login-content__button">
              <Button id="loginButton" type="primary" htmlType="submit">
                CONTINUAR
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { LoginForm };
