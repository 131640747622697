import React from "react";
import { CustomText } from "../../../../../utils/CustomText";
import deactivateIcon from "../../../../../assets/img/icons/deactivate-icon.svg";

const DeactivateConfirmationModalTemplate = ({ targetWorker }) => {
  const workerNameOnUppercase = () => {
    const completeName = targetWorker.nombre + " " + targetWorker.apellidos;
    const completeNameToUppercase = completeName.toUpperCase();
    return completeNameToUppercase;
  };
  return (
    <div style={{ textAlign: "center", paddingTop: "20px" }}>
      <img src={deactivateIcon} alt="icon" />

      <CustomText
        style={{
          fontSize: "24px",
          textAlign: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        Estás seguro que deseas
        <br />
        desactivar a:
      </CustomText>

      <CustomText boldType={700} style={{ color: "#EA3297", fontSize: "24px" }}>
        "{workerNameOnUppercase()}"
      </CustomText>
    </div>
  );
};

export { DeactivateConfirmationModalTemplate };
