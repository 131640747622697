import { notification } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const openSuccessNotification = ({ key, message, description, btn }) => {
  notification.open({
    icon: <CheckCircleOutlined style={{ color: "#27AE60" }} />,
    message,
    description,
    btn,
    key,
  });
};

export { openSuccessNotification };
