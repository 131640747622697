import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workers: [],
  totalOfRegisters: 0,
  workerCreated: false,
  workerEdited: false,
  workerStateChanged: false,
};

const workersSlice = createSlice({
  name: "workersSlice",
  initialState: initialState,
  reducers: {
    setWorkers(state, { payload }) {
      state.workers = payload;
    },
    setTotalOfRegisters(state, { payload }) {
      state.totalOfRegisters = payload;
    },
    setWorkerCreated(state, { payload }) {
      state.workerCreated = payload;
    },
    setWorkerEdited(state, { payload }) {
      state.workerEdited = payload;
    },
    setWorkerStateChanged(state, { payload }) {
      state.workerStateChanged = payload;
    },
  },
});

export const workersActions = workersSlice.actions;
export { workersSlice };
