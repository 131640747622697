import React, { useState, useEffect } from "react";
import "./CreateOrCheckNotificationsScreen.less";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InternalScreensLayout } from "../../../../utils/InternalScreensLayout";
import { CustomText } from "../../../../utils/CustomText";
import goBackArrow from "../../../../assets/img/icons/arrow-blue-left.svg";
import addNotificationsIcon from "../../../../assets/img/icons/add_notification_icon.svg";
import editNotificationsIcon from "../../../../assets/img/icons/edit_notifications_icon.svg";
import eyeIcon from "../../../../assets/img/icons/eye_icon.svg";
import { NotificationsForm } from "./NotificationsForm";
import { NotificationsPreview } from "./NotificationsPreview";
import { CustomModal } from "../../../../utils/CustomModal";
import { ConfirmationModalTemplate } from "./ConfirmationModalTemplate";
import NotificationsServices from "../../../../services/endpoints/NotificationsServices";
import { isTokenValid } from "../../../../utils/token";
import { logOut } from "../../../../utils/auth";
import { CustomLoader } from "../../../../utils/CustomLoader";
import { applyAlert } from "../../../../utils/utils";
import { CustomAlert } from "../../../../utils/CustomAlert";
import { notificationsActions } from "../../../../redux/slices/notifications";

const CreateOrCheckNotificationsScreen = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const notificationType = params.notificationType;
  const thereIsId = params.notificationId ? true : false;
  const [allUsersSelected, setAllUsersSelected] = useState(false);
  const [usersSVG, setUsersSVG] = useState(null);
  const [campain, setCampain] = useState("");
  const [subject, setSubject] = useState("");
  const [shortMessage, setShortMessage] = useState("");
  const [messageImg, setMessageImg] = useState(null);
  const [prevImgList, setPrevImgList] = useState(null);
  const [largeMessage, setLargeMessage] = useState("");
  const [initialNotification, setInitialNotification] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const token = localStorage.getItem("authToken");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [standarizedNotInfo, setStandarizedNotInfo] = useState({});

  const getNotificationById = async () => {
    setLoading(true);
    try {
      const response = await NotificationsServices.getNotificationById(
        token,
        params.notificationId
      );
      const notification = response.data.notificacion;
      console.log(notification);
      const notificationInfo = {
        campain: notification.campana,
        subject: notification.titulo,
        shortMessage: notification.subtitulo,
        largeMessage: notification.mensaje,
      };
      if (notification.afiliado) {
        const thisStantadirezNotInfo = {
          rut: notification.afiliado.rut,
          date: notification.fechaEnvio,
        };
        setStandarizedNotInfo(thisStantadirezNotInfo);
      }
      setInitialNotification(notificationInfo);
      setCampain(notificationInfo.campain);
      setSubject(notificationInfo.subject);
      setShortMessage(notificationInfo.shortMessage);
      setLargeMessage(notificationInfo.largeMessage);
      if (notification.imagen && notification.imagen !== "") {
        const imageName = notification.imagen.split("/").slice(-1)[0];
        const actualImageList = [
          {
            uid: 1,
            name: imageName,
            status: "done",
            url: notification.imagen,
            ext: imageName.split(".")[1],
          },
        ];
        console.log(actualImageList[0]);
        onImagePreview(actualImageList[0]);
        setPrevImgList(actualImageList);
      }
      setMessageImg(notification.imagen);
      setLoading(false);
    } catch (e) {
      let errorMessage = "Ocurrió un error en la comunicación con el servidor.";
      if (e.data) {
        if (e.data.message) {
          errorMessage = e.data.message;
        } else {
          errorMessage =
            "Ocurrió un error al traer información de la notificación.";
        }
      }
      applyAlert(setError, setErrorMessage, errorMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (notificationType !== "general") {
      dispatch(notificationsActions.setTableToShow(2));
    }
    if (thereIsId) {
      getNotificationById();
    }
  }, [thereIsId, params]);

  const handleOnChangeCampain = (e) => {
    setCampain(e.target.value);
  };

  const handleOnChangeSubject = (e) => {
    setSubject(e.target.value);
  };

  const handleOnChangeShortMessage = (e) => {
    setShortMessage(e.target.value);
  };

  const handleOnChangeLargeMessage = (editorState) => {
    const editorText = editorState.toHTML();
    setLargeMessage(editorText);
  };

  const handleAllUsersSelected = () => {
    if (allUsersSelected === false) {
      setUsersSVG(null);
    }
    setAllUsersSelected(!allUsersSelected);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const onImagePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      console.log(1, file.preview);
      setMessageImg(file.preview);
    } else {
      // let blob = new Blob([file.url], { type: `image/${file.ext}` });
      // const base64 = await getBase64(blob);
      const base64 = await getBase64FromUrl(file.url);
      console.log(2, base64);
      setMessageImg(base64);
    }
  };

  const handleSend = async () => {
    setShowConfirmationModal(false);
    setLoading(true);
    if (!isTokenValid(token)) {
      logOut(navigate, dispatch);
    } else {
      let csvBase64 = null;
      if (usersSVG) {
        csvBase64 = await getBase64(usersSVG);
      }
      const data = {
        toAll: allUsersSelected,
        csvFile: csvBase64,
        titulo: subject,
        tipo: notificationType,
        mensaje: largeMessage,
        subtitulo: notificationType === "general" ? shortMessage : "subtitulo",
        texto: largeMessage,
        campana: campain,
        photo: messageImg ? messageImg : "",
      };
      console.log("data:", data);
      try {
        const response = await NotificationsServices.postNotification(
          token,
          data
        );
        if (response.data === "OK") {
          const receivers = allUsersSelected
            ? "TODOS los usuarios"
            : "usuarios adjuntos en archivo csv";
          dispatch(
            notificationsActions.setLastNotificationReceivers(receivers)
          );
          dispatch(notificationsActions.setSendingNotificationSuccess(true));
          setLoading(false);
          navigate("/notifications");
        } else {
          setLoading(false);
        }
      } catch (e) {
        let errorMessage =
          "Ocurrió un error en la comunicación con el servidor.";
        if (e.data) {
          if (e.data.message) {
            errorMessage = e.data.message;
          } else {
            errorMessage = "Ocurrió un error en el envío de la notificación.";
          }
        }
        applyAlert(setError, setErrorMessage, errorMessage);
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <CustomLoader visible={loading} />

      <InternalScreensLayout>
        {error && <CustomAlert message={errorMessage} type={"error"} />}
        <CustomModal
          onOk={handleSend}
          visible={showConfirmationModal}
          setVisible={setShowConfirmationModal}
          cancelText="Cancelar"
          okText={notificationType === "general" ? "Sí, enviar" : "Sí, crear"}
        >
          <ConfirmationModalTemplate
            notificationType={notificationType}
            allUsersSelected={allUsersSelected}
          />
        </CustomModal>

        <div id="CreateOrCheckNotifications">
          <Link to={"/notifications"} id="goBackLink">
            <img src={goBackArrow} alt="Go back arrow" />
            <CustomText style={{ color: "#00A9DF", fontSize: "18px" }}>
              Volver
            </CustomText>
          </Link>
          <div id="formAndPreviewContainer">
            {notificationType === "general" && (
              <div id="formContainer">
                <div className="titleContainer">
                  <img src={addNotificationsIcon} alt="Notifications icon" />
                  <CustomText
                    boldType={600}
                    style={{ fontSize: "20px", color: "rgba(0, 0, 0, 0.8)" }}
                  >
                    Crear Notificación{" "}
                    {notificationType.charAt(0).toUpperCase() +
                      notificationType.slice(1)}
                  </CustomText>
                </div>
                <NotificationsForm
                  notificationType={notificationType}
                  handleOnChangeCampain={handleOnChangeCampain}
                  handleOnChangeSubject={handleOnChangeSubject}
                  handleOnChangeShortMessage={handleOnChangeShortMessage}
                  handleOnChangeLargeMessage={handleOnChangeLargeMessage}
                  largeMessage={largeMessage}
                  allUsersSelected={allUsersSelected}
                  handleAllUsersSelected={handleAllUsersSelected}
                  usersSVG={usersSVG}
                  setUsersSVG={setUsersSVG}
                  params={params}
                  subject={subject}
                  initialNotification={initialNotification}
                  setShowConfirmationModal={setShowConfirmationModal}
                  messageImg={messageImg}
                  setMessageImg={setMessageImg}
                  onImagePreview={onImagePreview}
                  getBase64={getBase64}
                  prevImgList={prevImgList}
                  setPrevImgList={setPrevImgList}
                />
              </div>
            )}
            <div id="previewContainer">
              <div className="titleContainer">
                <img src={eyeIcon} alt="Notifications icon" />
                <CustomText
                  boldType={600}
                  style={{ fontSize: "20px", color: "rgba(0, 0, 0, 0.8)" }}
                >
                  Vista Previa de Notificación
                </CustomText>
              </div>
              <NotificationsPreview
                notificationType={notificationType}
                subject={subject}
                shortMessage={shortMessage}
                largeMessage={largeMessage}
                messageImg={messageImg}
                standarizedNotInfo={standarizedNotInfo}
              />
            </div>
          </div>
        </div>
      </InternalScreensLayout>
    </React.Fragment>
  );
};

export { CreateOrCheckNotificationsScreen };
