import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import {
  SmileOutlined,
  HomeOutlined,
  CommentOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import logoUno from "../../../assets/img/Logo_UNOafp.svg";

const MenuSider = () => {
  const location = useLocation();
  const { userData } = useSelector((state) => state.login);

  return (
    <div id="sider">
      <img id="headerLogo" src={logoUno} alt="Logo UNO" />
      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/home" icon={<HomeOutlined />}>
          <Link style={{ textDecoration: "none" }} to="/home">
            Inicio
          </Link>
        </Menu.Item>

        <Menu.Item icon={<SmileOutlined />} key="/users">
          <Link style={{ textDecoration: "none" }} to="/users">
            Usuarios APP
          </Link>
        </Menu.Item>

        <Menu.Item icon={<CommentOutlined />} key="/notifications">
          <Link style={{ textDecoration: "none" }} to={"/notifications"}>
            Notificaciones
          </Link>
        </Menu.Item>

        {userData.rol === 1 && (
          <Menu.Item icon={<TeamOutlined />} key="/workers">
            <Link style={{ textDecoration: "none" }} to={"/workers"}>
              Colaboradores
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export { MenuSider };
