import React, { useState, useEffect } from "react";
import "./CreateOrEditWorkerScreen.less";
import { useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { InternalScreensLayout } from "../../../../utils/InternalScreensLayout";
import { CustomText } from "../../../../utils/CustomText";
import { applyAlert } from "../../../../utils/utils";
import { CustomAlert } from "../../../../utils/CustomAlert";
import goBackArrow from "../../../../assets/img/icons/arrow-blue-left.svg";
import { UserAddOutlined } from "@ant-design/icons";
import editNotificationsIcon from "../../../../assets/img/icons/edit_notifications_icon.svg";
import { WorkerForm } from "./WorkerForm";
import { isTokenValid } from "../../../../utils/token";
import { logOut } from "../../../../utils/auth";
import WorkersServices from "../../../../services/endpoints/WorkersServices";
import { workersActions } from "../../../../redux/slices/workers";
import { CustomLoader } from "../../../../utils/CustomLoader";

const CreateOrEditWorkerScreen = () => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const editMode = params.workerId ? true : false;
  const [initialWorker, setInitialWorker] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getWorkerById = async () => {
    setLoading(true);

    try {
      const response = await WorkersServices.getWorkerById(
        token,
        params.workerId
      );
      console.log(response.data);
      const actualWorker = {
        names: response.data.nombre,
        lastnames: response.data.apellidos,
        email: response.data.email,
        rol: `${response.data.rol.id}`,
      };
      setInitialWorker(actualWorker);
      setLoading(false);
    } catch (e) {
      let errorMessage = "Ocurrió un error en la comunicación con el servidor.";
      if (e.data) {
        if (e.data.message) {
          errorMessage = e.data.message;
        } else {
          errorMessage =
            "Ocurrió un error al traer información del colaborador.";
        }
      }
      applyAlert(setError, setErrorMessage, errorMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editMode) {
      getWorkerById();
    }
  }, [editMode, params]);

  const handleCreateOrEdit = async (values) => {
    setLoading(true);
    let data = {
      email: values.email,
      nombre: values.names,
      apellidos: values.lastnames,
      rol: values.rol,
    };

    if (!isTokenValid(token)) {
      setLoading(true);
      logOut(navigate, dispatch);
    } else {
      try {
        if (editMode) {
          await WorkersServices.editWorker(token, params.workerId, data);
          dispatch(workersActions.setWorkerEdited(true));
          setLoading(false);
          navigate("/workers");
        } else {
          await WorkersServices.createWorker(token, data);
          dispatch(workersActions.setWorkerCreated(true));
          setLoading(false);
          navigate("/workers");
        }
      } catch (e) {
        let errorMessage =
          "Ocurrió un error en la comunicación con el servidor.";
        if (e.data) {
          if (e.data.message) {
            errorMessage = e.data.message;
          } else {
            errorMessage = "Ocurrió un error al crear al colaborador.";
          }
        }
        applyAlert(setError, setErrorMessage, errorMessage);
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <CustomLoader visible={loading} />
      <InternalScreensLayout>
        {error && <CustomAlert message={errorMessage} type={"error"} />}
        <div id="CreateOrEditWorker">
          <Link to={"/workers"} id="goBackLink">
            <img src={goBackArrow} alt="Go back arrow" />
            <CustomText style={{ color: "#00A9DF", fontSize: "18px" }}>
              Volver
            </CustomText>
          </Link>
          <div id="workerFormContainer">
            <div id="formContainer">
              <div className="titleContainer">
                {editMode ? (
                  <img src={editNotificationsIcon} alt="Worker icon" />
                ) : (
                  <UserAddOutlined style={{ fontSize: "18px" }} />
                )}
                <CustomText
                  boldType={600}
                  style={{ fontSize: "20px", color: "rgba(0, 0, 0, 0.8)" }}
                >
                  {editMode ? "Editar" : "Crear Nuevo"} Colaborador
                </CustomText>
              </div>
              <WorkerForm
                handleCreateOrEdit={handleCreateOrEdit}
                editMode={editMode}
                params={params}
                initialWorker={initialWorker}
              />
            </div>
          </div>
        </div>
      </InternalScreensLayout>
    </React.Fragment>
  );
};

export { CreateOrEditWorkerScreen };
