import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { RoundButtonWithDropdown } from "../../../../../utils/RoundButtonWithDropDown";
import { useNavigate } from "react-router-dom";
import WorkersServices from "../../../../../services/endpoints/WorkersServices";
import { workersActions } from "../../../../../redux/slices/workers";

const WorkersTable = ({
  handleShowDeactivationModal,
  loading,
  setLoading,
  setLoadingScreen,
  getWorkers,
  searchingWord,
  applyAlert,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { workers, totalOfRegisters } = useSelector((state) => state.workers);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: totalOfRegisters,
      pageSizeOptions: [],
    },
  });
  const [workersToShow, setWorkersToShow] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleEdit = (id) => {
    navigate(`/workers/edit/${id}`);
  };

  const handleChangeWorkerState = async (worker, active) => {
    if (active) {
      handleShowDeactivationModal(worker);
    } else {
      setLoadingScreen(true);
      try {
        const data = {
          active: true,
        };
        await WorkersServices.changeWorkerState(token, worker.id, data);
        dispatch(workersActions.setWorkerStateChanged(true));
        setLoadingScreen(false);
      } catch (e) {
        let errorMessage =
          "Ocurrió un error en la comunicación con el servidor.";
        if (e.data) {
          if (e.data.message) {
            errorMessage = e.data.message;
          } else {
            errorMessage =
              "Ocurrió un error al consultar datos de colaboradores.";
          }
        }
        applyAlert(setError, setErrorMessage, errorMessage);
        setLoadingScreen(false);
      }
    }
  };

  const columns = [
    {
      title: "ID",
      key: 1,
      sorter: (a, b) => a.id.toString().localeCompare(b.id),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p
          style={{
            color: record.active ? "#595959" : "rgba(89, 89, 89, 0.39)",
          }}
        >
          {record.id}
        </p>
      ),
    },
    {
      title: "Nombres",
      key: 2,
      sorter: (a, b) => a.nombre.toString().localeCompare(b.nombre),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p
          style={{
            color: record.active ? "#595959" : "rgba(89, 89, 89, 0.39)",
          }}
        >
          {record.nombre}
        </p>
      ),
    },
    {
      title: "Apellidos",
      key: 3,
      sorter: (a, b) => a.apellidos.toString().localeCompare(b.apellidos),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p
          style={{
            color: record.active ? "#595959" : "rgba(89, 89, 89, 0.39)",
          }}
        >
          {record.apellidos}
        </p>
      ),
    },
    {
      title: "Correo electrónico",
      key: 4,
      sorter: (a, b) => a.email.toString().localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p
          style={{
            color: record.active ? "#595959" : "rgba(89, 89, 89, 0.39)",
          }}
        >
          {record.email}
        </p>
      ),
    },
    {
      title: "Rol",
      key: 4,
      sorter: (a, b) => a.rol.nombre.toString().localeCompare(b.rol.nombre),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p
          style={{
            color: record.active ? "#595959" : "rgba(89, 89, 89, 0.39)",
          }}
        >
          {record.rol.nombre === "Admin" ? "Administrador" : record.rol.nombre}
        </p>
      ),
    },
    {
      title: "Acción",
      key: 5,
      render: (record) => (
        <RoundButtonWithDropdown
          buttonTitle={<EllipsisOutlined />}
          style={{ marginLeft: "8px" }}
          dropdownOverlay={
            <Menu>
              <Menu.Item onClick={() => handleEdit(record.id)} key={"1"}>
                Editar
              </Menu.Item>
              <Menu.Item
                onClick={() => handleChangeWorkerState(record, record.active)}
                key={4.2}
              >
                {record.active ? "Desactivar" : "Activar"}
              </Menu.Item>
            </Menu>
          }
        />
      ),
    },
  ];

  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    try {
      const skip = 20 * pagination.current - 20;
      const response = await WorkersServices.getWorkers(token, skip);
      setWorkersToShow(response.data.rows);
      setTableParams({
        pagination,
      });
      setLoading(false);
    } catch (e) {}
  };

  const handleApplySearchingWord = async () => {
    setLoading(true);
    try {
      const response = await WorkersServices.getWorkers(
        token,
        0,
        searchingWord
      );
      setWorkersToShow(response.data.rows);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: response.data.total,
          pageSizeOptions: [],
        },
      });
      setLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    if (searchingWord !== "") {
      handleApplySearchingWord();
    } else {
      setWorkersToShow([]);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: totalOfRegisters,
          pageSizeOptions: [],
        },
      });
    }
  }, [searchingWord]);

  return (
    <div id="workersTable">
      {(workers || workersToShow.length > 0) && (
        <Table
          loading={loading}
          pagination={tableParams.pagination}
          columns={columns}
          dataSource={workersToShow.length > 0 ? workersToShow : workers}
          onChange={handleTableChange}
        />
      )}
    </div>
  );
};

export { WorkersTable };
