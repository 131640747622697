import request from "../../index";

function authenticate(data) {
  return request({
    url: "/colaborador/login",
    method: "POST",
    data,
    public: true,
  });
}

function getPassByEmail(data) {
  return request({
    url: "/colaborador/recuperar",
    method: "POST",
    data,
    public: true,
  });
}

const LoginServices = {
  authenticate,
  getPassByEmail,
};

export default LoginServices;
