import React from "react";
import { Button } from "antd";
import { CustomText } from "../CustomText";
import "./CustomButton.less";

const CustomButton = ({ title, icon, type, ...restOfProps }) => {
  const thisType = type ? type : "primary";

  return (
    <Button type={thisType} className="customButtom" {...restOfProps}>
      {icon && icon}
      {title}
    </Button>
  );
};

export { CustomButton };
