import React from "react";
import "./Home.less";
import {
  SmileOutlined,
  CommentOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { InternalScreensLayout } from "../../../utils/InternalScreensLayout";
import { CustomText } from "../../../utils/CustomText";
import { HomeCard } from "./HomeCard";

const HomeScreen = () => {
  const { userData } = useSelector((state) => state.login);
  console.log(userData);
  return (
    <InternalScreensLayout>
      <div id="home">
        <div id="homeTitle">
          <CustomText boldType={600} style={{ fontSize: "22px" }}>
            Hola {userData.nombre} {userData.apellidos}
          </CustomText>
          <CustomText
            boldType={400}
            style={{ fontSize: "22px", marginLeft: "5px" }}
          >
            ¿Qué necesitas?
          </CustomText>
        </div>
        <div id="homeCardsContainer">
          <HomeCard
            icon={
              <SmileOutlined style={{ color: "#00A9DF", fontSize: "25px" }} />
            }
            title={"Usuarios registrados APP"}
            linkName={"Ver todos los usuarios"}
            listItems={[
              "Breve información del usuario",
              "Últimas Actividades del usuario en la APP.",
              "Descargar reporte.",
            ]}
            linkRoute={"/users"}
          />
          <HomeCard
            icon={
              <CommentOutlined style={{ color: "#00A9DF", fontSize: "25px" }} />
            }
            title={"Notificaciones"}
            linkName={"Ver todas las notificaciones"}
            listItems={[
              "Historial de notificaciones",
              "Crea nuevas notificaciones.",
              "Reenviar notificaciones.",
            ]}
            linkRoute={"/notifications"}
          />
          {userData.rol === 1 && (
            <HomeCard
              icon={
                <TeamOutlined style={{ color: "#00A9DF", fontSize: "25px" }} />
              }
              title={"Gestión de colaboradores"}
              linkName={"Ver todos los colaboradores"}
              listItems={[
                "Breve información del colaborador.",
                "Crear y editar colaboradores.",
                "Activar y desactivar colaboradores.",
              ]}
              linkRoute={"/workers"}
            />
          )}
        </div>
      </div>
    </InternalScreensLayout>
  );
};

export { HomeScreen };
