import React from "react";
import "../Notifications.less";
import { CustomText } from "../../../../../utils/CustomText";

const NotificationsTypeSelector = ({ selectedTable, setSelectedTable }) => {
  const buttonStyle = {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "18px",
  };
  const selectedButtonStyle = {
    color: "#00A9DF",
    fontSize: "18px",
  };

  const onPressType = (number) => setSelectedTable(number);

  return (
    <div id="notificationsTypeSelector">
      <div
        className={
          selectedTable === 1 ? "selectedSelectorButton" : "selectorButton"
        }
      >
        <CustomText
          boldType={selectedTable === 1 ? 600 : 500}
          style={selectedTable === 1 ? selectedButtonStyle : buttonStyle}
          onClick={() => onPressType(1)}
        >
          Generales
        </CustomText>
      </div>
      <div
        className={
          selectedTable === 2 ? "selectedSelectorButton" : "selectorButton"
        }
      >
        <CustomText
          boldType={selectedTable === 2 ? 600 : 500}
          style={selectedTable === 2 ? selectedButtonStyle : buttonStyle}
          onClick={() => onPressType(2)}
        >
          Estandarizadas
        </CustomText>
      </div>
      <div
        className={
          selectedTable === 3 ? "selectedSelectorButton" : "selectorButton"
        }
      >
        <CustomText
          boldType={selectedTable === 3 ? 600 : 500}
          style={selectedTable === 3 ? selectedButtonStyle : buttonStyle}
          onClick={() => onPressType(3)}
        >
          Enviadas
        </CustomText>
      </div>
    </div>
  );
};

export { NotificationsTypeSelector };
