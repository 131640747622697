import React, { useState } from "react";
import "../Password.less";
import { Button, Col, Form, Input, Row } from "antd";
import LoginServices from "../../../../services/endpoints/LoginServices";
import { applyAlert } from "../../../../utils/utils";
import { useDispatch } from "react-redux";
import { loginActions } from "../../../../redux/slices/login";

const RecoveryPassForm = ({
  setEnterPassState,
  setError,
  setErrorMessage,
  setShowTransparentLoader,
  navigate,
}) => {
  const dispatch = useDispatch();
  const validateMessages = {
    required: "El campo es requerido.",
    types: {
      email: "El correo debe tener un formato válido.",
    },
  };

  const onSend = async (values) => {
    setShowTransparentLoader(true);
    const data = {
      email: values.email,
    };
    try {
      const response = await LoginServices.getPassByEmail(data);

      console.log(response);
      setShowTransparentLoader(false);
      // setEnterPassState(true);
      dispatch(loginActions.setNewPassSent(true));
      navigate("/");
    } catch (e) {
      let errorMessage =
        "Ocurrió un error en la comunicación con el servidor, vuelve a intentarlo más tarde.";
      if (e.data) {
        if (e.data.message) {
          errorMessage = e.data.message;
        } else {
          errorMessage = "Ocurrió un error en la solicitud de recuperación.";
        }
      }
      applyAlert(setError, setErrorMessage, errorMessage);
      setShowTransparentLoader(false);
    }
  };

  return (
    <Form
      id="passwordForm"
      layout="vertical"
      onFinish={onSend}
      validateMessages={validateMessages}
    >
      <Row justify={"center"}>
        <Col xs={22} md={24}>
          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
            <Input placeholder="Correo electrónico" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={"center"}>
        <Col xs={22} md={24}>
          <Form.Item>
            <div id="password-content__button">
              <Button id="passwordButton" type="primary" htmlType="submit">
                ENVIAR
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { RecoveryPassForm };
