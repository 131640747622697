import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import resendOutlinedIcon from "../../../../../assets/img/icons/resend-outlined.svg";
import { useNavigate } from "react-router-dom";
import NotificationsServices from "../../../../../services/endpoints/NotificationsServices";

const GeneralNotificationsTable = ({
  setLoading,
  loading,
  searchingWord,
  setSearchingError,
}) => {
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const { generalNotifications, totalOfRegisterGeneralNot } = useSelector(
    (state) => state.notifications
  );
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: totalOfRegisterGeneralNot,
      pageSizeOptions: [],
    },
  });
  const [notificationsToShow, setNotidicationsToShow] = useState([]);

  const handleResend = (id) => {
    navigate(`/notifications/create/general/${id}`);
  };

  const columns = [
    {
      title: "Notificación",
      key: 1,
      sorter: (a, b) => a.titulo.toString().localeCompare(b.titulo),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.titulo}</p>,
    },
    {
      title: "Receptor",
      key: 2,
      sorter: (a, b) =>
        a.personasEnvio.toString().localeCompare(b.personasEnvio),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <p>{record.toAll ? "Todos" : record.personasEnvio}</p>
      ),
    },
    {
      title: "Fecha de envío",
      key: 3,
      sorter: (a, b) => a.fechaEnvio.toString().localeCompare(b.fechaEnvio),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.fechaEnvio}</p>,
    },
    {
      title: "Leído por",
      key: 4,
      sorter: (a, b) => a.leidas.toString().localeCompare(b.leidas),
      sortDirections: ["descend", "ascend"],
      render: (record) => <p>{record.leidas} usuarios</p>,
    },
    {
      title: "Acción",
      key: 5,
      render: (record) => (
        <div id="editIconContainer">
          <img
            id="resendIcon"
            src={resendOutlinedIcon}
            alt="Resend icon"
            onClick={() => handleResend(record.id)}
          />
        </div>
      ),
    },
  ];

  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    try {
      const skip = 20 * pagination.current - 20;
      const response = await NotificationsServices.getNotifications(
        token,
        "general",
        skip
      );
      setNotidicationsToShow(response.data.notificaciones);
      setTableParams({
        pagination,
      });
      setLoading(false);
    } catch (e) {}
  };

  const handleApplySearchingWord = async () => {
    setLoading(true);
    try {
      const response = await NotificationsServices.getNotifications(
        token,
        "general",
        0,
        searchingWord
      );
      if (response.data.notificaciones.length === 0) {
        setSearchingError(true);
      }
      setNotidicationsToShow(response.data.notificaciones);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: response.data.count,
          pageSizeOptions: [],
        },
      });
      setLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    if (searchingWord !== "") {
      handleApplySearchingWord();
    } else {
      setNotidicationsToShow([]);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 20,
          total: totalOfRegisterGeneralNot,
          pageSizeOptions: [],
        },
      });
    }
  }, [searchingWord]);

  return (
    <div id="generalNotificationsTable">
      {(generalNotifications || notificationsToShow.length > 0) && (
        <Table
          loading={loading}
          columns={columns}
          pagination={tableParams.pagination}
          dataSource={
            notificationsToShow.length > 0
              ? notificationsToShow
              : generalNotifications
          }
          onChange={handleTableChange}
        />
      )}
    </div>
  );
};

export { GeneralNotificationsTable };
