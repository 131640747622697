import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { loginSlice } from "./slices/login";
import { usersSlice } from "./slices/users";
import { workersSlice } from "./slices/workers";
import { notificationsSlice } from "./slices/notifications";

const reducers = combineReducers({
  login: loginSlice.reducer,
  users: usersSlice.reducer,
  workers: workersSlice.reducer,
  notifications: notificationsSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
