import React from "react";
import { CustomText } from "../../../../../utils/CustomText";
import smillingMessageIcon from "../../../../../assets/img/icons/smilling_message_icon.svg";

const ConfirmationModalTemplate = ({ notificationType, allUsersSelected }) => {
  return (
    <div style={{ textAlign: "center", paddingTop: "20px" }}>
      <img src={smillingMessageIcon} alt="icon" />

      <CustomText
        style={{
          fontSize: "24px",
          textAlign: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        {notificationType === "general"
          ? "Estás seguro que deseas"
          : "¿Estás seguro que deseas crear una"}
        <br />
        {notificationType === "general"
          ? "enviar la notificación a:"
          : "notificación estandarizada?"}
      </CustomText>
      {notificationType === "general" && (
        <CustomText
          boldType={700}
          style={{ color: "#EA3297", fontSize: "24px" }}
        >
          {allUsersSelected
            ? '"TODOS LOS USUARIOS"'
            : '"LOS USUARIOS ADJUNTOS EN EL ARCHIVO CSV"'}
        </CustomText>
      )}
    </div>
  );
};

export { ConfirmationModalTemplate };
