import request from "../../index";

function getNotifications(token, type, skip, searchingWord) {
  const thisSkip = skip ? skip : 0;
  const thisType = type ? `&tipo=${type}` : "";
  console.log(thisType);
  const thisSearchWithWord = searchingWord
    ? `&searchText=${searchingWord}`
    : "&searchText";
  return request({
    url: `/notificacion/listar?skip=${thisSkip}${thisType}${thisSearchWithWord}`,
    method: "GET",
    token,
  });
}

function getNotificationById(token, id) {
  return request({
    url: `/notificacion/admin/get/${id}`,
    method: "GET",
    token,
  });
}

function postNotification(token, data) {
  return request({
    url: "/notificacion/crear",
    method: "POST",
    token,
    data,
  });
}

const NotificationsServices = {
  postNotification,
  getNotifications,
  getNotificationById,
};

export default NotificationsServices;
