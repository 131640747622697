import React from "react";
import "./CustomAlert.less";
import { Alert } from "antd";

const CustomAlert = ({ type, message, ...restOfProps }) => {
  return (
    <div id="custom-alert">
      <Alert message={message} type={type} {...restOfProps} />
    </div>
  );
};

export { CustomAlert };
