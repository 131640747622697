import React, { useState, useEffect } from "react";
import "./Notifications.less";
import { useSelector, useDispatch } from "react-redux";
import { Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { InternalScreensLayout } from "../../../../utils/InternalScreensLayout";
import { CustomText } from "../../../../utils/CustomText";
import { CustomButton } from "../../../../utils/CustomButton";
import { CustomAlert } from "../../../../utils/CustomAlert";
import { NotificationsTypeSelector } from "./NotificationsTypeSelector";
import { GeneralNotificationsTable } from "./GeneralNotificationsTable";
import { StandardizedNotificationsTable } from "./StandardizedNotificationsTable";
import { SentNotificationsTable } from "./SentNotificationsTable";
import { openSuccessNotification } from "../../../../utils/notifications";
import { notificationsActions } from "../../../../redux/slices/notifications";
import NotificationsServices from "../../../../services/endpoints/NotificationsServices";
import { isTokenValid } from "../../../../utils/token";
import { logOut } from "../../../../utils/auth";
import { applyAlert } from "../../../../utils/utils";

const NotificationsScreen = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { Search } = Input;
  const navigate = useNavigate();
  const [selectedTable, setSelectedTable] = useState(1);
  const [loadingData, setLoadingData] = useState(false);
  const { sendingNotificationSuccess, lastNotificationReceivers, tableToShow } =
    useSelector((state) => state.notifications);
  const [searchInputValue, setInputValue] = useState("");
  const [searchingWord, setSearchingWord] = useState("");
  const [searchingError, setSearchingError] = useState(false);

  useEffect(() => {
    if (tableToShow !== 1) {
      setSelectedTable(tableToShow);
      dispatch(notificationsActions.setTableToShow(1));
    }
  }, [tableToShow]);

  useEffect(() => {
    setInputValue("");
    setSearchingWord("");
  }, [selectedTable]);

  useEffect(() => {
    if (searchingError) {
      applyAlert(
        setError,
        setErrorMessage,
        "No se encontraron registros con esa palabra clave."
      );
      setSearchingError(false);
      setInputValue("");
      setSearchingWord("");
    }
  }, [searchingError]);

  const getNotifications = async () => {
    setLoadingData(true);
    if (!isTokenValid(token)) {
      logOut(navigate, dispatch);
    } else {
      try {
        let [generalNotResponse, standarizedNotResponse, sentNotResponse] =
          await Promise.all([
            NotificationsServices.getNotifications(token, "general"),
            NotificationsServices.getNotifications(token, "estandar"),
            NotificationsServices.getNotifications(token),
          ]);
        dispatch(
          notificationsActions.setGeneralNotifications(
            generalNotResponse.data.notificaciones
          )
        );
        dispatch(
          notificationsActions.setTotalOfRegisterGeneralNot(
            generalNotResponse.data.count
          )
        );
        dispatch(
          notificationsActions.setStandarizedNotifications(
            standarizedNotResponse.data.notificaciones
          )
        );
        dispatch(
          notificationsActions.setTotalOfRegisterStandarizedNot(
            standarizedNotResponse.data.count
          )
        );
        dispatch(
          notificationsActions.setSentNotifications(
            sentNotResponse.data.notificaciones
          )
        );
        dispatch(
          notificationsActions.setTotalOfRegisterSentNot(
            sentNotResponse.data.count
          )
        );
        setLoadingData(false);
      } catch (e) {
        let errorMessage =
          "Ocurrió un error en la comunicación con el servidor.";
        if (e.data) {
          if (e.data.message) {
            errorMessage = e.data.message;
          } else {
            errorMessage =
              "Ocurrió un error al consultar datos de notificaciones.";
          }
        }
        applyAlert(setError, setErrorMessage, errorMessage);
        setLoadingData(false);
      }
    }
  };

  const handleApplySearch = (value) => {
    setSearchingWord(value);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleCreateNotification = () => {
    navigate("/notifications/create/general");
  };

  useEffect(() => {
    if (sendingNotificationSuccess) {
      openSuccessNotification({
        key: "sendingSuccess",
        message: "Notificación enviada exitosamente",
        description: `Se ha enviado una notificación a: ${lastNotificationReceivers}`,
        btn: (
          <CustomButton
            title="Aceptar"
            style={{ height: "24px", width: "86px", borderRadius: "4px" }}
            onClick={() => notification.close("sendingSuccess")}
          />
        ),
      });
      dispatch(notificationsActions.setSendingNotificationSuccess(false));
      dispatch(notificationsActions.setLastNotificationReceivers(""));
    }
  }, [sendingNotificationSuccess]);

  return (
    <InternalScreensLayout>
      {error && <CustomAlert message={errorMessage} type={"error"} />}
      <div id="notifications">
        <div id="notificationsTitle">
          <CustomText
            boldType={600}
            style={{ fontSize: "24px", color: "#E3007D" }}
          >
            Notificaciones
          </CustomText>
        </div>
        <div id="inputAndButtonContainer">
          <Search
            enterButton
            allowClear
            placeholder="Buscar"
            onSearch={handleApplySearch}
            value={searchInputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{ width: "360px" }}
          />
          {selectedTable === 1 && (
            <CustomButton
              onClick={handleCreateNotification}
              title={"Crear notificación general"}
            />
          )}
        </div>
        <NotificationsTypeSelector
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
        />
        {selectedTable === 1 && (
          <GeneralNotificationsTable
            loading={loadingData}
            setLoading={setLoadingData}
            searchingWord={searchingWord}
            setSearchingError={setSearchingError}
          />
        )}
        {selectedTable === 2 && (
          <StandardizedNotificationsTable
            loading={loadingData}
            setLoading={setLoadingData}
            searchingWord={searchingWord}
            setSearchingError={setSearchingError}
          />
        )}
        {selectedTable === 3 && (
          <SentNotificationsTable
            loading={loadingData}
            setLoading={setLoadingData}
            searchingWord={searchingWord}
            setSearchingError={setSearchingError}
          />
        )}
      </div>
    </InternalScreensLayout>
  );
};

export { NotificationsScreen };
