const applyAlert = (setError, setErrorMessage, message) => {
  setErrorMessage(message);
  setError(true);
  setTimeout(() => {
    setError(false);
    setErrorMessage("");
  }, 6000);
};

const normalizeStrings = (string) =>
  string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export { applyAlert, normalizeStrings };
