import React from "react";
import { Col, Row } from "antd";
import "./InitialScreensLayout.less";
import { CustomText } from "../CustomText";
import logoUno from "../../assets/img/Logo_UNOafp.svg";

const InitialScreensLayout = ({ title, children }) => {
  return (
    <div id="InitialScreensLayout">
      <div id="formContainer">
        <img id="logoUno" src={logoUno} alt="Logo UNO" />
        <Row justify={"center"}>
          <Col xs={{ span: 20, offset: 3 }} md={{ span: 24, offset: 0 }}>
            <CustomText style={{ fontSize: "24px", marginBottom: "12px" }}>
              {title}
            </CustomText>
          </Col>
        </Row>

        {children}
      </div>
      <CustomText
        boldType={500}
        style={{ fontSize: "16px", opacity: "50%", marginBottom: "35px" }}
      >
        © Todos los derechos reservados UNO afp 2022
      </CustomText>
    </div>
  );
};

export { InitialScreensLayout };
