import React from "react";
import PropTypes from "prop-types";
import "./CustomText.less";

const CustomText = ({ children, style, boldType, ...restOfProps }) => {
  let fontStyle = {
    fontFamily: "Montserrat",
    fontWeight: boldType,
    letterSpacing: 0.3,
    color: "#202020",
    fontSize: 14,
    marginBottom: 0,
  };

  if (style) {
    fontStyle = { ...fontStyle, ...style };
  }

  return (
    <p style={fontStyle} {...restOfProps}>
      {children}
    </p>
  );
};

CustomText.propTypes = {
  boldType: PropTypes.oneOf([400, 500, 600, 700]),
  style: PropTypes.any,
  props: PropTypes.shape(),
};

export { CustomText };
